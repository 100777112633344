.feedbackErrorMessages {
  color: #f00;
  font-size: smaller;
  font-weight: 400;
  margin: 5px 0 0;
  overflow: hidden;
  padding: 2px;
  width: 95%;
}

.dropZoneBorderError {
  background: #e3e3e3;
  padding: 50px 25px;
  text-align: center;
  border: 2px #f00 dashed;
}

.dropZoneBorder {
  background: #fdfcfc;
  padding: 50px 25px;
  text-align: center;
  border: 4px  dashed #1D78FF;
  border-spacing: 10px;
  border-radius: 10px;
}