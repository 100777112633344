.infoContainer {
  margin-bottom: 2rem;
}

.infoTableContainer {
  margin-bottom: 2rem;
  background-color: #eee;
  border-radius: 16px;
  padding: 10px;
  width: 95%;
  margin: auto;
}

.generalInfoForExpedient {
  padding: 15px;
}

.generalInfoForExpedient th {
  text-align: right;
  padding-right: 2rem;
  width: 50%;
}

.generalInfoForExpedient td {
  text-align: left;
  padding-left: 2rem;
  width: 50%;
}

.partsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.addPartsButton {
  text-align: right;
  padding: 10px 1rem 5px 0;
}

.addPartsButton>button {
  padding: 12px 8px;
  font-weight: 600;
}

.partsTableTitle {
  text-align: center;
  font-size: 1.75rem;
  margin: 10px 0;
}

.partsListingContainer {
  border: 1px #DDD solid;
  border-radius: 15px;
  padding: 10px 20px;
}

.actionElements {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.actionElements>a {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 18px;
  font-weight: bold;
}

.editIconStyles {
  color: #fff;
  background-color: #02CC98;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
}