.templateBadgeElementList {
  --gap-elements: var(--spacing-sm);
}

.templateBadgeElementList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-elements);
  list-style: none;
  padding: 0;
  align-items: start;
}