.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
  padding: 1.5rem;
}

.actionButtons button {
  padding: 10px;
  font-weight: 600;
}

.input {
  padding: 1.3rem !important;
  font-size: 16px;
}