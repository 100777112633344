.classButtonsSearch {
    margin-bottom: 10px;
    text-align: right;
}

.classHelperContextual {
    text-align: right;
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 3000px) and (min-width: 1024px) {
    .classContainer{
        width: 50%;
        float: right;
        margin-top: -5px;
        margin-bottom: 1rem;
    }
    
    .classButtonsSearch button:first-child{
        margin-right: 15px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 800px) {
    .classContainer{
        width: 50%;
        float: right;
        margin-top: -16px;
    }
    
    .classButtonsSearch button:first-child{
        margin-right: 15px;
    }

}

@media screen and (max-width: 799px) and (min-width: 400px) {  

    .classButtonsSearch button:first-child{
        margin-right: 15px;
    }

}

@media screen and (max-width: 399px) and (min-width: 200px) {    
    .classButtonsSearch button{
        width: 60px;
        height: 25px;
        font-size: 10px;
        line-height: .2;
    }
    .classButtonsSearch button:first-child{
        margin-right: 5px;
    }
}