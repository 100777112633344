.reset-size-font {
    font-size: 1.2em !important;
    font-weight: bold;
}

/* React-Select Error Class */
.select-error .my-select__control {
    border-color: red;
}

/* DatePicker Errors */
.datepicker-error {
    border: 1px solid red !important;
}
.class {
    z-index: 10000 !important;
}

.error-span {
    color: red !important;
}