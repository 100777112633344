.menuItem {
  --padding-vertical-menu-item: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  --padding-horizontal-menu-item: calc(var(--spacing-xs) + var(--spacing-xxxs));
  --padding-menu-item: var(--padding-vertical-menu-item) var(--padding-horizontal-menu-item);
  --border-radius-menu-item: var(--border-radius-medium);
  --background-color-disabled-menu-item: var(--color-stroke-3);
  --background-color-hovered-menu-item: var(--color-stroke-5);
}

.menuItem {
  cursor: pointer;
  border-radius: var(--border-radius-menu-item);
  padding: var(--padding-menu-item);
}

.menuItemHover {
  composes: menuItem;
  background-color: var(--background-color-hovered-menu-item);
}

.menuItemDisabled {
  composes: menuItem;
  cursor: default;
  background-color: var(--background-color-disabled-menu-item);
}

.menuItem:focus {
  background-color: var(--background-color-hovered-menu-item);
  outline: none;
}