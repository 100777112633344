.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
}

.title {
  text-align: center;
}

.largeSize {
  width: 100%;
  padding: 6px 12px 0;
}

.mediumSize {
  width: 50%;
  padding: 6px 12px 0;
}

.smallSize {
  width: 33%;
  padding: 6px 12px 0;
}

.containerRowFields {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.fieldLabel {
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.actionButtonsContainer {
  padding-top: 4rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1.5rem;
}

.button {
  padding: 14px 16px;
  font-weight: 600;
}

.calendarFieldApparience {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendarGroup {
  display: flex;
  width: 100%;
}

.calendarIcon {
  font-size: 28px;
  padding: 3.5px 5px;
  color: #ffffff;
}

.datePickerElement {
  width: 100%;
}

.iconPicker {
  width: auto;
  min-height: 43px;
  max-height: 43px;
  background-color: #40b0f3;
  border-radius: 0 6px 6px 0;
}

.inputDate {
  background: #fff;
  border: .09rem solid #c1c1c1;
  padding: 0.7rem 0.60rem !important;
  border-radius: 4px 0 0 4px;
  width: 100%;
  height: 43px;
}

.textarea {
  width: 100%;
  resize: none;
  border-radius: 8px;
  padding: 10px;
  border: .06rem solid #c1c1c1;
}

.checkGroupField {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

input.checkAparience {
  width: 16px;
  height: 16px;
}

.instructionsText {
  margin: 2rem 0 0.5rem 1rem;
}

.instructionsText>span {
  font-size: 16px;
}

.legalRepresentantTitle {
  margin: auto;
  font-weight: 600;
  text-align: center;
}

.tabMenus {
  padding-bottom: 1rem;
}

.input {
  padding: 1.3rem !important;
  font-size: 14px;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  padding: 12px;
}