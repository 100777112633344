.menu {
  --padding-menu: var(--spacing-xs);
  --font-size-menu: var(--font-size-md);
  --border-width-menu: calc(var(--spacing-xxxs)/2);
  --border-menu: var(--border-width-menu) solid var(--color-stroke);
  --border-radius-menu: var(--border-radius-medium);
  --background-color-menu: var(--color-empty);
  --min-width-menu: calc(var(--spacing-enormous) * 2);
  --animation-time-menu-show: 0.15s;
  --animation-time-menu-hide: 0.2s;
}

.menu {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  z-index: 100;
  list-style: none;
  user-select: none;
  padding: var(--padding-menu);
  font-size: var(--font-size-menu);
  border: var(--border-menu);
  border-radius: var(--border-radius-menu);
  background-color: var(--background-color-menu);
  min-width: var(--min-width-menu);
}

.menuOpening {
  composes: menu;
  animation: menuShow var(--animation-time-menu-show) ease-out;
}

.menuClosing {
  composes: menu;
  animation: menuHide var(--animation-time-menu-hide) ease-out forwards;
}

.menu:focus {
  outline: none;
}

@keyframes menuShow {
  from {
    opacity: 0;
  }
}

@keyframes menuHide {
  to {
    opacity: 0;
  }
}