.badgeWiOptions {
  --border-radius-badge: var(--border-radius-medium);
  --border-badge: var(--spacing-xxxs) solid var(--color-principal);
  --background-color-badge: var(--color-empty);
  --width-content-column-badge: 1fr;
  --width-dropdown-column-badge: auto;
  --columns-badge: var(--width-content-column-badge) var(--width-dropdown-column-badge);
  --gap-badge: var(--spacing-xxs);
}

.badgeWiOptions_content {
  --max-inline-size-content: calc(var(--spacing-md) * 14);
  --color-content: var(--color-secondary);
  --font-size-content: var(--font-size-base);
  --font-weight-content: var(--font-weight-bold);
  --padding-content: var(--spacing-xs) var(--spacing-xxs) var(--spacing-xs) var(--spacing-md);
  --border-radius-content: var(--border-radius-badge);
  --outline-focused-content: var(--color-text) solid var(--spacing-xxs);
}

.badgeWiOptions {
  border-radius: var(--border-radius-badge);
  border: var(--border-badge);
  background-color: var(--background-color-badge);
  display: inline-grid;
  gap: var(--gap-badge);
  align-items: center;
  grid-template-columns: var(--columns-badge);
}

.badgeWiOptions_content {
  padding: var(--padding-content);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-inline-size: var(--max-inline-size-content);
  color: var(--color-content);
  font-size: var(--font-size-content);
  font-style: normal;
  font-weight: var(--font-weight-content);
  line-height: normal;
  margin: 0;
  border: none;
  border-radius: var(--border-radius-content);
  background-color: inherit;
}

.badgeWiOptions_content:focus-visible {
  outline: var(--outline-focused-content);
}