.menuButton {
  border: none;
  --background-color-menu-button: var(--color-empty);
  --color-menu-button: var(--color-text);
}

.menuButton {
  border: none;
  background-color: var(--background-color-menu-button);
  color: var(--color-menu-button);
}