.actionWidget {
  --font-size-widget: var(--font-size-base);
  --color-text-widget: var(--color-text);
  --font-weight-widget: var(--font-weight-normal);
  --gap-widget: var(--spacing-sm);
  --border-radius-widget: var(--border-radius-large);
  --outline-color-focus-visible-widget: var(--color-text);
  --scale-click-widget: 0.97;
  --transition-click-widget: transform 0.1s ease;
  --padding-widget: var(--spacing-sm);
  --filter-opacity-disabled-widget: opacity(0.2);
}

.actionWidget_icon {
  --color-text-icon: var(--color-empty);
  --border-radius-icon: var(--border-radius-all);
  --size-icon: var(--spacing-gigantic);
  --padding-icon: var(--spacing-sm);
  --background-color-icon: var(--color-secondary);
  --font-size-icon: var(--font-size-2xl);
  --aspect-ratio-icon: 1/1;
}

.actionWidget {
  display: grid;
  justify-items: center;
  gap: var(--gap-widget);
  padding: var(--padding-widget);
  border-radius: var(--border-radius-widget);
  border: none;
  background-color: transparent;
  transition: var(--transition-click-widget);
}

.actionWidget_icon {
  background-color: var(--background-color-icon);
  padding: var(--padding-icon);
  border-radius: var(--border-radius-icon);
  width: var(--size-icon);
  height: var(--size-icon);
  display: block;
  aspect-ratio: var(--aspect-ratio-icon);
  color: var(--color-text-icon);
  font-size: var(--font-size-icon);
  display: grid;
  place-items: center;
}

.actionWidget_name {
  color: var(--color-text-widget);
  text-align: center;
  font-size: var(--font-size-widget);
  font-style: normal;
  font-weight: var(--font-weight-widget);
}

.actionWidget:focus-visible {
  outline-color: var(--outline-color-focus-visible-widget);
}

.actionWidget:active:not(:disabled) {
  transform: scale(var(--scale-click-widget));
}

.actionWidget:disabled {
  filter: var(--filter-opacity-disabled-widget);
}