:root {
  /* Colors */
  --color-text: #606060;
  --color-text-strong: #252525;
  --color-accent: #41D9B2;
  --color-accent-2: #DFF9F2;
  --color-strong-accent: #02CC98;
  --color-stroke: #C4C4C4;
  --colo-stroke-2: #F4F4F4;
  --color-stroke-3: #D6D6D6;
  --color-stroke-4: #EFEFEF;
  --color-stroke-5: #f9f9f9;
  --color-empty: #FFFFFF;
  --color-secondary: #0067CE;
  --color-principal: #1D78FF;
  --color-secondary-light: #3AB8FF;
  --color-info: #E3EEFF;
  --color-info-2: #F1F6FF;
  --color-alert: #CC3D2A;
  --color-alert-strong: #FF0000;


  /* Font-size */
  --font-size-xxs: 0.5rem;
  /* 8px */
  --font-size-xs: 0.625rem;
  /* 10px */
  --font-size-sm: 0.75rem;
  /* 12px */
  --font-size-sm-plus: 0.8125rem;
  /* 13px */
  --font-size-md: 0.875rem;
  /* 14px */
  --font-size-base: 1rem;
  /* 16px */
  --font-size-lg: 1.125rem;
  /* 18px */
  --font-size-xl: 1.25rem;
  /* 20px */
  --font-size-2xl: 1.5rem;
  /* 24px */
  --font-size-3xl: 1.75rem;
  /* 28px */
  --font-size-4xl: 1.875rem;
  /* 30px */
  --font-size-5xl: 2rem;
  /* 32px */
  --font-size-6xl: 2.125rem;
  /* 34px */
  --font-size-7xl: 2.25rem;
  /* 36px */
  --font-size-8xl: 2.5rem;
  /* 40px */
  --font-size-9xl: 4rem;
  /* 64px */
  --font-size-10xl: 4.5rem;
  /* 72px */


  /* Spacing */
  --spacing-xxxs: 0.125rem;
  /* 2px */
  --spacing-xxs: 0.25rem;
  /* 4px */
  --spacing-xs: 0.5rem;
  /* 8px */
  --spacing-sm: 0.75rem;
  /* 12px */
  --spacing-md: 1rem;
  /* 16px */
  --spacing-lg: 1.5rem;
  /* 24px */
  --spacing-xl: 2rem;
  /* 32px */
  --spacing-xxl: 2.5rem;
  /* 40px */
  --spacing-xxxl: 3rem;
  /* 48px */
  --spacing-xxxxl: 3.5rem;
  /* 56px */
  --spacing-huge: 4rem;
  /* 64px */
  --spacing-gigantic: 4.5rem;
  /* 72px */
  --spacing-enormous: 5rem;
  /* 80px */


  /* Breakpoints */
  --breakpoint-mobile-sm: 20rem;
  /* 320px */
  --breakpoint-mobile-md: 23.4375rem;
  /* 375px */
  --breakpoint-mobile-lg: 26.5625rem;
  /* 425px */
  --breakpoint-tablet: 48rem;
  /* 768px */
  --breakpoint-tablet-lg: 53.125rem;
  /* 850px */
  --breakpoint-laptop: 64rem;
  /* 1024px */
  --breakpoint-laptop-lg: 90rem;
  /* 1440px */
  --breakpoint-desktop: 160rem;
  /* 2560px */


  /* Font-weight */
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;


  /* Border-radius */
  --border-radius-small: 3px;
  --border-radius-medium: 5px;
  --border-radius-large: 10px;
  --border-radius-xlarge: 20px;
  --border-radius-all: 100%;
}