.tabPanelContent {
  --grid-template-columns-content: max(calc(var(--spacing-enormous) * 2)) 1fr;
  --grid-template-areas-content: 'createWidget templateList';
  --gap-content: var(--spacing-lg);
}

.tabPanelContent {
  display: grid;
  grid-template-columns: var(--grid-template-columns-content);
  grid-template-areas: var(--grid-template-areas-content);
  gap: var(--gap-content);
  align-items: start;
}

.tabPanelContent_createWidget {
  grid-area: createWidget;
}

.tabPanelContent_templateList {
  grid-area: templateList;
}

@media (width <=60rem) {
  .tabPanelContent {
    --grid-template-columns-content: 1fr;
    --grid-template-areas-content:
      'templateList'
      'createWidget';
  }
}