.tabPanelContent {
  --border-radius-content: 0px var(--border-radius-large) var(--border-radius-large) var(--border-radius-large);
  --border-content: calc(var(--spacing-xxxs) / 2) solid var(--color-stroke);
  --padding-content: var(--spacing-xxxl) var(--spacing-md);
}

.tabPanelContent {
  border-radius: var(--border-radius-content);
  border: var(--border-content);
  padding: var(--padding-content);
}