.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
}

.containerNotResultsArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.containerNotResultsArea span {
  font-size: 18px;
  color: #cccccc;
  padding: 2rem 0;
}

.containerNotResultsArea svg {
  font-size: 18px;
  font-weight: bold;
  color: #CC6666;
}

.resultsSearch {
  list-style-type: none;
}

.actionElements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  gap: 20px;
}

.actionElements>a {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 18px;
  font-weight: bold;
}

.editIconStyles {
  color: #fff;
  background-color: #02CC98;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
}

.deleteIconStyles {
  color: #fff;
  background-color: #f44336;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
}

.viewIconStyles {
  color: #fff;
  background-color: #a9cbff;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
}