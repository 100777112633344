.partContainer {
  display: grid;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.generalPartInfo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-right: 1rem;
}

.fullName {
  width: 40%;
}

.partType {
  width: 30%;
}

.partClasification {
  width: 30%;
}

.lista-tareas__tarea:hover .lista-tareas__icono-accion {
  opacity: 0.7;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.partContainer:hover .editIconStyles {
  opacity: 0.75;
}

.partContainer:hover .deleteIconStyles {
  opacity: 0.75;
}

.actionElements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  gap: 20px;
}

.actionElements>a {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 18px;
  font-weight: bold;
}

.editIconStyles {
  color: #fff;
  background-color: #02CC98;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
  opacity: 0;
}

.deleteIconStyles {
  color: #fff;
  background-color: #f44336;
  padding: 6px 20px;
  border-radius: 5px 5px 0 0;
  opacity: 0;
}