.react-select-input-select__control {
  background-color: var(--color-info) !important;
  padding: var(--spacing-xxs) var(--spacing-xs) !important;
  border-radius: var(--border-radius-medium) !important;
  line-height: var(--spacing-lg) !important;
  border: var(--spacing-xxxs) solid transparent !important;
  outline: unset !important;
  font-size: var(--font-size-md);
}

.react-select-input-select__control--is-focused {
  box-shadow: unset !important;
  border-color: var(--color-secondary) !important;
}

.react-select-input-select__single-value {
  color: var(--color-text) !important;
}

.react-select-input-select__menu-list {
  background-color: var(--color-info) !important;
}

.react-select-input-select__option--is-focused {
  filter: saturate(2) !important;
}

.react-select-input-select__option--is-selected {
  background-color: var(--color-principal) !important;
  color: var(--color-empty) !important;
}

.react-select-input-select__data-indicator-arrow .react-select-input-select__indicators {
  width: calc(var(--spacing-md) + var(--spacing-xxs)) !important;
  height: calc(var(--spacing-md) + var(--spacing-xxxs)) !important;
  background-image: url('./assets/arrow_down.svg') !important;
  background-repeat: no-repeat !important;
  place-self: center;
  margin-right: var(--spacing-xs) !important;
}

.react-select-input-select__indicator-separator {
  display: none !important;
}

.react-select-input-select__dropdown-indicator {
  display: none !important;
}

.react-select-input-select__data-error .react-select-input-select__control {
  border: var(--spacing-xxxs) solid var(--color-alert) !important;
}

.react-select-input-select__data-disabled-field .react-select-input-select__control {
  background: var(--color-stroke) !important;
}

.react-select-input-select__data-indicator-arrow.react-select-input-select__data-disabled-field .react-select-input-select__indicators {
  background-image: url('./assets/gray_arrow_down.svg') !important;
}

.react-select-input-select__multi-value {
  background-color: var(--color-secondary-light) !important;
  color: var(--color-empty) !important;
  border-radius: var(--border-radius-medium) !important;
}

.react-select-input-select__multi-value--is-disabled {
  filter: grayscale(0.8);
}

.react-select-input-select__multi-value--is-disabled .react-select-input-select__multi-value__remove {
  display: none;
}

.react-select-input-select__multi-value__remove:hover {
  background-color: var(--color-secondary-light) !important;
  color: var(--color-empty) !important;
  cursor: pointer !important;
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0 !important;
}

.react-select-input-select__multi-value__label {
  color: var(--color-empty) !important;
  overflow: hidden !important;
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

.react-select-input-select__clear-indicator {
  display: none !important;
}

.react-select-input-select__data-is-small-size .react-select-input-select__control {
  padding: var(--spacing-xxxs) !important;
  line-height: var(--font-size-xs) !important;
  font-size: var(--font-size-xs);
  margin: var(--spacing-xxxs) !important;
  min-height: unset !important;
}

.react-select-input-select__data-is-small-size .react-select-input-select__indicators {
  background-image: url('./assets/arrow_down.svg') !important;
  width: calc(var(--spacing-xs) + var(--spacing-xxxs)) !important;
  height: var(--spacing-xs) !important;
  margin-right: var(--spacing-xxxs) !important;
}

.react-select-input-select__data-is-small-size.react-select-input-select--is-disabled .react-select-input-select__indicators {
  background-image: url('./assets/gray_arrow_down.svg') !important;
}

.react-select-input-select__data-is-small-size .react-select-input-select__value-container {
  margin: 0 !important;
  padding: 0 !important;
}