.modal {
  /*Old design color palette*/
  --dark-transparent-1: rgba(0, 0, 0, 0.467);
  --dark-transparent-2: rgba(0, 0, 0, .0625);
  --sky-blue: #c7ddff;
}

.modal {
  --width-modal: 100%;
  --height-modal: 100dvh;
  --background-color-modal: var(--dark-transparent-1);
  --padding-modal: var(--spacing-lg) var(--spacing-sm);
}

.modal_content {
  --width-content: 80%;
  --border-radius-content: var(--spacing-xs);
  --padding-header: var(--spacing-md);
  --line-height-header: 1.5;
  --font-size-header: var(--font-size-xl);
  --background-color-header: var(--sky-blue);
  --background-color-body: var(--color-empty);
  --padding-content: var(--spacing-sm);
  --gap-footer: var(--spacing-xs);
  --border-color-footer: var(--dark-transparent-2);
  --border-footer: 1px solid var(--border-color-footer);
  --background-color-footer: var(--color-empty);
}

.modal {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  width: var(--width-modal);
  height: var(--height-modal);
  display: grid;
  align-content: start;
  justify-items: center;
  background-color: var(--background-color-modal);
  padding: var(--padding-modal);
  overflow-y: auto;
}

.modal_content {
  width: var(--width-content);
}

.modal_content>header {
  padding: var(--padding-header);
  line-height: var(--line-height-header);
  font-size: var(--font-size-header);
  background-color: var(--background-color-header);
  border-top-right-radius: var(--border-radius-content);
  border-top-left-radius: var(--border-radius-content);
}

.modal_content>section {
  background-color: var(--background-color-body);
  padding: var(--padding-content);
}

.modal_content>footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-footer);
  padding: var(--padding-content);
  border-top: var(--border-footer);
  border-bottom-right-radius: var(--border-radius-content);
  border-bottom-left-radius: var(--border-radius-content);
  background-color: var(--background-color-footer);
  width: auto;
  z-index: unset;
}