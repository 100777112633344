.actions {
  --gap-horizontal: var(--spacing-sm);
  --gap-vertical: var(--spacing-xl);
  --gap-actions: vaR(--gap-vertical) var(--gap-horizontal);
  --min-width-column: min(calc(var(--spacing-enormous) * 2), 100%);
  --max-width-column: 1fr;
  --width-column: minmax(var(--min-width-column), var(--max-width-column));
}

.actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--width-column));
  gap: var(--gap-actions);
}