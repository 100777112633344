.tabs [role="tablist"] {
  --gap-tablist: var(--spacing-sm);
  --padding-tablist: 0;
}

.tabs [role="tab"] {
  --padding-tab: var(--spacing-xs) var(--spacing-md);
  --background-color-tab: var(--color-secondary);
  --background-color-focused-tab: var(--color-stroke-4);
  --border-radius-tab: var(--border-radius-large) var(--border-radius-large) 0 0;
  --color-tab: var(--color-accent-2);
  --color-focused-tab: var(--color-secondary);
  --font-size-tab: var(--font-size-lg);
  --font-weight-tab: var(--font-weight-bold);
  --line-height-tab: 141%;
  --letter-spacing-tab: -0.023rem;
  --outline-width-focused-tab: var(--spacing-xxs);
  --outline-color-focused-tab: var(--color-text);
  --outline-focused-tab: var(--outline-width-focused-tab) solid var(--outline-color-focused-tab);
}

.tabs [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-tablist);
  list-style: none;
  padding: var(--padding-tablist);
}

.tabs [role="tab"] {
  padding: var(--padding-tab);
  background-color: var(--background-color-tab);
  border-radius: var(--border-radius-tab);
  cursor: pointer;
  color: var(--color-tab);
  text-align: center;
  font-size: var(--font-size-tab);
  font-weight: var(--font-weight-tab);
  line-height: var(--line-height-tab);
  letter-spacing: var(--letter-spacing-tab);
}

.tabs [role="tab"][aria-selected="true"] {
  background-color: var(--background-color-focused-tab);
  color: var(--color-secondary);
}

.tabs [role="tab"]:focus-visible {
  outline: var(--outline-focused-tab);
}

@media (width <=48rem) {
  .tabs [role="tab"] {
    flex-basis: 100%;
  }
}